import React, { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowIcon, Col, H1, IconButton, Input, LabeledInput, Row } from '@commonsku/styles';
import { getCountryOptions, getStateOptions } from '../company-search/selectors';
import { LabeledSelect } from '../helpers';
import { createAddAddress, CREATE_ADDRESS_FAILURE } from '../../actions/address';
import { useIdentity } from '../../hooks';

const CompanyAddressForm = memo((props) => {
  const {
    form,
    updateField,
    onContinue,
  } = props;

  const dispatch = useDispatch();
  const identity = useIdentity();
  const address_countries = getCountryOptions();

  const [showErrors, setShowErrors] = useState(false);

  const address_state_options = useMemo(() => {
    if (form.address_country && form.address_country.length > 0) {
      return getStateOptions(form.address_country);
    }
    return [];
  }, [form.address_country]);

  const canContinue = useMemo(() => {
    return form.address_line_1
      && form.address_city
      && form.address_country
      && form.address_postal;
  }, [form]);

  const handleContinue = () => {
    if (!canContinue) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    const address_data = {
      address_line_1: form.address_line_1,
      address_line_2: form.address_line_2,
      address_city: form.address_city,
      address_country: form.address_country,
      address_state: form.address_state,
      address_postal: form.address_postal,
      address_type: 'BOTH',
      tenant_default_billing: true,
      parent_type: 'TENANT',
      parent_id: identity.company_id,
      // duplicate: true,
    };
    Promise.resolve(dispatch(createAddAddress(address_data)))
      .then(action => {
        if (action && action.type === CREATE_ADDRESS_FAILURE) {
          return;
        }
        onContinue();
      });
  };

  return (
    <Row>
      <Col xs>
        <H1>Billing Address</H1>
      </Col>
      <Col xs>
        <LabeledSelect
          label='Country'
          name='address_country'
          options={address_countries}
          value={form.address_country}
          onChange={e => updateField('address_country', e.value)}
          required
          error={showErrors && !form.address_country}
        />
      </Col>
      <Col xs pt={10}>
        <LabeledInput
          label='Street address'
          name='address_line_1'
          placeholder='123 Street'
          value={form.address_line_1}
          onChange={e => updateField('address_line_1', e.target.value)}
          required
          error={showErrors && !form.address_line_1}
        />
      </Col>
      <Col xs>
        <Input
          value={form.address_line_2}
          placeholder='Apt/Suite (optional)'
          onChange={e => updateField('address_line_2', e.target.value)}
        />
      </Col>
      <Col xs md={4} mdStyle="padding-right: 10px; padding-top: 5px;">
        <LabeledInput
          label='City'
          name='address_city'
          placeholder='City'
          value={form.address_city}
          onChange={e => updateField('address_city', e.target.value)}
          required
          error={showErrors && !form.address_city}
        />
      </Col>
      <Col xs md={4}>
        <LabeledSelect
          label='State/Province'
          name='address_state'
          placeholder='State/Province'
          options={address_state_options}
          value={form.address_state}
          onChange={e => updateField('address_state', e ? e.value : '')}
        />
      </Col>
      <Col xs md={4} xsStyle="padding-top: 10px;" mdStyle="padding-left: 10px; padding-top: 5px;">
        <LabeledInput
          label='ZIP/Postal code'
          name='address_postal'
          placeholder='ZIP/Postal code'
          value={form.address_postal}
          onChange={e => updateField('address_postal', e.target.value)}
          required
          error={showErrors && !form.address_postal}
        />
      </Col>
      <Col xs pt={20}>
        <IconButton
          variant={'cta'}
          onClick={handleContinue}
          Icon={<ArrowIcon direction='right' />}
          iconPosition='right'
        >Continue</IconButton>
      </Col>
    </Row>
  );
});

export default CompanyAddressForm;
